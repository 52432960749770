<template>
  <div>
    <div class="flex back-blue text-white p-6 rounded align-center">
      <md-icon class="m-0 text-white text-3xl">help</md-icon>
      <h3 class="text-uppercase ml-3 text-2xl font-bold">PTE Practice</h3>
          <md-button v-if="layoutMeta == 'master'"
              class="bg-green text-white rounded-md md-dense m-0 text-uppercase ml-2"
              :to="{name: 'testCreation.create', query: {type: 'pte-practice'}}"
          >
            <md-icon class="text-white">add</md-icon> Create PTE Practice
          </md-button>
    </div>
    <CollapsComponent
      @on-navigate="onNavigate"
      :active-item="type"
      :child-items="getPTEPracticeQuestion"
    />

  </div>
</template>

<script>

import CollapsComponent from "./CollapseComponentTest.vue";
import data from '@/data/pte-practice/data';
import { mapActions, mapGetters } from 'vuex';
import { slugify } from '@/utils';

export default {
  data() {
      return {
          layoutMeta: 'student',
          items: data.readingPageNavigation,
      }
  },
  computed: {
    ...mapGetters({
      getPTEPracticeQuestion: "testCreation/getPTEPracticeQuestion",
    }),
    type() {
      return this.$route.query.type
    },
    userId() {
      return localStorage.getItem('user_id');
    },
  },
  components: {
    CollapsComponent,
  },
  methods: {
    ...mapActions({
      actPTEPracticeQuestion: "testCreation/actPTEPracticeQuestion",
      actTestSections: "testCreation/actTestSections",
      actExamEnroll: "examination/actExamEnroll",
    }),
  //  async onNavigate({child : test}) {
  //  console.log("mehedi")
  //   let { exam_enroll_id } = await this.actExamEnroll({
  //       user_id: this.userId,
  //       test_id: test.id,
  //       exam_id: "",
  //       is_exam: 0
  //     })

  //     this.$router.push({
  //       name: 'student.ptePracticePreview',
  //       query: {
  //         enrollId: exam_enroll_id,
  //         testId: test.id
  //       }
  //     });
  //   },
    onNavigate({child}) {
      if(this.layoutMeta=='student'){
        this.$router.push({
          name: 'student.ptePracticePreview',
          params: {
            slug: slugify(child.name),
            id: child.t_id
          }
        });
      }else{
        this.$router.push({
          name: 'teacher.ptePracticePreview',
          params: {
            slug: slugify(child.name),
            id: child.t_id
          }
        });
      }
      localStorage.setItem("pte_id",  child.id);
      this.$root.id = child.id;
      this.$root.name = child.name;
    }
  },
  mounted() {
    this.layoutMeta = this.$route.meta.layout;    
    //console.log("practice", this.items)
    this.actPTEPracticeQuestion(``);
    console.log(this.actPTEPracticeQuestion(``))
  }
};
</script>